import React from 'react';


function GuessButton(props){
    return(
        <button onClick={props.onClick}>
            {props.buttonLabel}
        </button>
    )
}

export default function GuessButtonOptionsPanel(props){

    function onAnswerPressed(answerID){
        props.onGuessMade(answerID);
    }

    function getAnswerString(guessOption){
        if (props.difficulty === "hard"){
            return guessOption.taxon.name;
        }
        else{
            return guessOption.taxon.common_name.name;
        }
    }

    return (
        <div>
            <br/><br/><br/>
            <GuessButton onClick={() => onAnswerPressed(0)} buttonLabel={getAnswerString(props.guessOptions[0])}/>{"  "}
            <GuessButton onClick={() => onAnswerPressed(1)} buttonLabel={getAnswerString(props.guessOptions[1])}/>{"  "}
            <GuessButton onClick={() => onAnswerPressed(2)} buttonLabel={getAnswerString(props.guessOptions[2])}/>{"  "}
            <GuessButton onClick={() => onAnswerPressed(3)} buttonLabel={getAnswerString(props.guessOptions[3])}/>
        </div>
    )
}