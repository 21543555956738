import { createContext, useContext } from "react"

export default function StreakCounterComponent(props){
    const gameContext = useContext(props.context);

    return(
        <div>
            {
                gameContext.score > 0 ?
                <h2>{"Current Streak: " + gameContext.score}</h2>
                : <h2>{gameContext.state === "preGame" ? "Click 'next creature' to begin!" : ""}</h2>
            }
            {
                localStorage.getItem("highscore_" + gameContext.taxon + gameContext.difficulty) !== null &&
                <h2>{"High Score:  " + localStorage.getItem("highscore_" + gameContext.taxon + gameContext.difficulty)}</h2>
            }
        </div>
    )
}