import React, { useState, useContext } from "react";

function GameStartOptionButton(props){
    return(
        <button disabled={props.disabled} onClick={() => props.onButtonPressed(props.taxon, props.difficulty)}>{props.buttonLabel}</button>
    )
}

export default function GameStartOptionsPanel(props){
    const gameContext = useContext(props.context);
    const [currentState, setState] = useState(gameContext.taxon === "" ? "Mammalia" : gameContext.taxon);

    return (
        <div>
            <h5>Taxon:</h5>
            <select value={currentState} onChange={(event) => setState(event.target.value)}>
                <option value="Mammalia">Mammals (Easy)</option>
                <option value="Reptilia">Reptiles (Easy)</option>
                <option value="Aves">Birds</option>
                <option value="Insecta">Insects</option>
                <option value="Arachnida">Arachnids</option>
                <option value="Plantae">Plants</option>
                <option value="Fungi">Fungi</option>
            </select>      
            <br/><br/>
            <GameStartOptionButton taxon={currentState} difficulty="easy" buttonLabel="Start Easy Game" onButtonPressed={props.onButtonPressed}/>{"  "}
            <GameStartOptionButton taxon={currentState} difficulty="medium" buttonLabel="Start Medium Game" onButtonPressed={props.onButtonPressed}/>{"  "}
            <GameStartOptionButton taxon={currentState} difficulty="hard" buttonLabel="Start Hard Game" onButtonPressed={props.onButtonPressed}/>
        </div>
    )    
}