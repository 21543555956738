import React, {useState, useContext} from 'react';
import SpeciesGuessButtonsPanel from './SpeciesGuessOptionButtonsPanel';
import coolPic from './iNat_Treehouse.jpg';

const url = new URL('https://www.inaturalist.org/observations.json');
url.searchParams.append('quality_grade','research');
url.searchParams.append('has[]','photos');
url.searchParams.append('has[]','geo');
url.searchParams.append('order_by','random');

let data = {};
let correctChoice = 0;
let speciesChoices = [];
let lastCorrect = {};
let globalPhotoIndex = 0;

function NextQuestionButton(props){
    return(
        <button onClick={props.onClick}>
            {props.buttonLabel}
        </button>
    )
}


export default function SpeciesGuessInterfaceComponent(props){
    const [currentState, setState] = useState("initial");
    const gameContext = useContext(props.context);

    //alert("local state: " + currentState + " game state: " + gameContext.state);
    // don't wait for user to press the button on our first question
    // correct choice hack to workaround double render issues. essentially a 'lock' on our getnextdataset
    if (gameContext.state === "gameStarted" && (currentState === "initial" || currentState === "postIncorrect")){
        setState("startingGame");
        if (correctChoice >= 0){
            getNextDataSet();
        }
    }

    async function getNextDataSet(){
        setState("loading");

        if (gameContext.difficulty === "easy"){
            await getNextDataSetEasy();
        }
        else {
            await getNextDataSetStandard();
        }

        globalPhotoIndex = 0;
        setState("asking");
    }

    async function getNextDataSetEasy(){
        console.log("getting next data set");

        correctChoice = -1;
        speciesChoices = [];

        // Prepare our bounded search query
        const coords = props.geoLocation.coords;
        let latitudeBounds = [Math.min(Math.max(coords.latitude - .15, -90) , 90), Math.min(Math.max(coords.latitude + .15, -90) , 90)];
        let longitudeBounds = [Math.min(Math.max(coords.longitude - .2, -180) , 180), Math.min(Math.max(coords.longitude + .2, -180) , 180)];
        const BoundedURL = new URL(url);
        BoundedURL.searchParams.append('swlat', latitudeBounds[0]);
        BoundedURL.searchParams.append('swlng', longitudeBounds[0]);
        BoundedURL.searchParams.append('nelat', latitudeBounds[1]);
        BoundedURL.searchParams.append('nelng', longitudeBounds[1]);
        BoundedURL.searchParams.append('per_page', 20 + Math.floor(Math.random() * 20));

        // Append our chosen iconic taxa :)
        BoundedURL.searchParams.append('iconic_taxa[]', gameContext.taxon);

        // iNat api uses mandatory pagination and doesn't support random ordering (despite what they say...)
        // so we abuse that by randomizing our entries per page and attempting to randomize our page to shuffle as much as possible. 
        // if this isn't sufficiently random, we could just grab 1 entry at a time but maaaan....
        // though note to self for later, maybe we want to grab the correct entry then use that taxon to filter for similar entries as a difficulty setting
        let page = Math.floor(Math.random() * 100);

        while (correctChoice < 0){
            // Apply our randomized page
            const paginatedURL = new URL(BoundedURL);
            paginatedURL.searchParams.append('page', page);

            const response = await fetch(paginatedURL.toString());
            let rawData = await response.text();
            data = JSON.parse(rawData);
            // shuffle our data using Fisher-Yates
            for (let i = 0; i < data.length - 1; i++){
                let j = i + Math.floor(Math.random() * (data.length - i));
                let entry = data[j];
                data[j] = data[i];
                data[i] = entry;
            }
            // filter non english or non common-named entries
            data = data.filter((entry) => {return entry.taxon !== null && entry.taxon.common_name !== null && entry.taxon.common_name.lexicon === "English"});
            // filter entries that aren't specific enough
            data = data.filter((entry) => {return entry.taxon.rank === "species" || entry.taxon.rank === "genus"});

            for (let i = speciesChoices.length; i < 4; i++){
                for (let j = 0; j < speciesChoices.length; j++){
                    // (try to) filter entries that match our selected correct choice
                    data = data.filter((entry) => {return entry.taxon.id !== speciesChoices[j].taxon.id});
                }
                
                if (data.length > 0){
                    speciesChoices[i] = data[0];
                }
            }

            if (speciesChoices.length >= 4){
                correctChoice = Math.floor(Math.random() * 4);
                //for (let i = 0; i < 4; i++) alert(speciesChoices[i].taxon.common_name.name)
            }
            else{
                // If this page was no good, slice ours in half until we get a good one (or just default to low numbers...)
                correctChoice = -1;
                page = Math.max(Math.floor(page/2), Math.floor(Math.random() * 5));
            }
        }

        lastCorrect = speciesChoices[correctChoice];
    }

    async function getNextDataSetStandard(){
        console.log("getting next data set");

        correctChoice = -1;
        speciesChoices = [];

        // Prepare our bounded search query to find our seed species
        const coords = props.geoLocation.coords;
        let latitudeBounds = [Math.min(Math.max(coords.latitude - .15, -90) , 90), Math.min(Math.max(coords.latitude + .15, -90) , 90)];
        let longitudeBounds = [Math.min(Math.max(coords.longitude - .2, -180) , 180), Math.min(Math.max(coords.longitude + .2, -180) , 180)];
        const BoundedURL = new URL(url);
        BoundedURL.searchParams.append('swlat', latitudeBounds[0]);
        BoundedURL.searchParams.append('swlng', longitudeBounds[0]);
        BoundedURL.searchParams.append('nelat', latitudeBounds[1]);
        BoundedURL.searchParams.append('nelng', longitudeBounds[1]);
        BoundedURL.searchParams.append('per_page', 20 + Math.floor(Math.random() * 20));

        // Append our chosen iconic taxa :)
        BoundedURL.searchParams.append('iconic_taxa[]', gameContext.taxon);

        // iNat api uses mandatory pagination and doesn't support random ordering (despite what they say...)
        // so we abuse that by randomizing our entries per page and attempting to randomize our page to shuffle as much as possible. 
        // if this isn't sufficiently random, we could just grab 1 entry at a time but maaaan....
        // though note to self for later, maybe we want to grab the correct entry then use that taxon to filter for similar entries as a difficulty setting
        let page = Math.floor(Math.random() * 100);

        // need a fallback for these higher difficulties in case we can't find enough species within the family!! maybe start getting to higher orders after a while
        let filterAncestry = [];
        let filterTaxon = "";
        let filterTaxonDepth = 3;

        while (correctChoice < 0){
            // Apply our randomized page
            const paginatedURL = new URL(BoundedURL);
            paginatedURL.searchParams.append('page', page);
            if (filterTaxon !== ""){
                paginatedURL.searchParams.append('taxon_id', filterTaxon);
            }

            const response = await fetch(paginatedURL.toString());
            let rawData = await response.text();
            data = JSON.parse(rawData);
            // shuffle our data using Fisher-Yates
            for (let i = 0; i < data.length - 1; i++){
                let j = i + Math.floor(Math.random() * (data.length - i));
                let entry = data[j];
                data[j] = data[i];
                data[i] = entry;
            }
            // filter non english or non common-named entries
            if (gameContext.difficulty === "medium"){
                data = data.filter((entry) => {return entry.taxon !== null && entry.taxon.common_name !== null && entry.taxon.common_name.lexicon === "English"});
            }
            else if (gameContext.difficulty === "hard"){
                data = data.filter((entry) => {return entry.taxon !== null && entry.taxon.name !== null && entry.taxon.name !== ""});
            }
            // filter entries that aren't specific enough
            data = data.filter((entry) => {return entry.taxon.rank === "species"});

            for (let i = speciesChoices.length; i < 4; i++){
                for (let j = 0; j < speciesChoices.length; j++){
                    // (try to) filter entries that match our selected correct choice
                    data = data.filter((entry) => {return entry.taxon.id !== speciesChoices[j].taxon.id});
                }
                
                if (data.length > 0){
                    speciesChoices[i] = data[0];

                    // Implies that this is our first pick, which means we now need to filter any future picks by this taxon
                    if (speciesChoices.length === 1){
                        let ancestry = speciesChoices[i].taxon.ancestry.split('/');
                        if (ancestry.length >= filterTaxonDepth){
                            filterAncestry = speciesChoices[i].taxon.ancestry.split('/');
                            filterTaxon = filterAncestry[filterAncestry.length - filterTaxonDepth];
                        }

                        data = data.filter((entry) => {return entry.taxon.ancestry.split('/').includes(filterTaxon)});
                    }

                }
                else {
                    // We need to broaden our search by traversing the ancestry backwards
                    filterTaxonDepth = filterTaxonDepth + 1;
                    filterTaxon = filterAncestry[filterAncestry.length - filterTaxonDepth];
                    break;
                }
            }

            if (speciesChoices.length >= 4){
                // shuffle our choices using Fisher-Yates since otherwise they'll be sorted by taxon depth
                for (let i = 0; i < speciesChoices.length - 1; i++){
                    let j = i + Math.floor(Math.random() * (speciesChoices.length - i));
                    let entry = speciesChoices[j];
                    speciesChoices[j] = speciesChoices[i];
                    speciesChoices[i] = entry;
                }

                correctChoice = Math.floor(Math.random() * 4);
            }
            else{
                // If this page was no good, slice ours in half until we get a good one (or just default to low numbers...)
                correctChoice = -1;
                page = page < 5 ? Math.floor(Math.random() * 100) : Math.floor(page/2);
            }
        }

        lastCorrect = speciesChoices[correctChoice];
    }

    function onGuessMade(guessID){
        if (guessID === correctChoice){
            props.onGuessMade(true);
            setState("postCorrect");
        } else{
            props.onGuessMade(false);
            setState("postIncorrect");
        }
    }

    function CreaturePhotoComponent(props){
        const [photoIndex, setPhotoIndex] = useState(globalPhotoIndex);

        if (lastCorrect.photos !== undefined){
            if (photoIndex >= lastCorrect.photos.length){
                globalPhotoIndex = 0;
                setPhotoIndex(0);
            }
        }

        function tryCyclePhotos(){
            if (lastCorrect.photos.length > 1){
                globalPhotoIndex = (globalPhotoIndex + 1) % lastCorrect.photos.length
                setPhotoIndex( globalPhotoIndex );
            }
        }

        if (gameContext.state === "gameStarted" || gameContext.state === "postGame") {
            if ( (currentState === "initial" || currentState === "loading" || currentState === "startingGame") && (lastCorrect !== undefined) ){
                return <img src={coolPic} height="256" width="256" alt='Thanks Mark'/>;
            }
            else{
                return <img src={lastCorrect.photos[photoIndex].medium_url} onClick={tryCyclePhotos} alt=':)'/>;
            }
        }
    }

    return(
        <div>
            {
                // creature image
                <CreaturePhotoComponent />
            }<br/>
            {
                // guess result text
                (currentState === "postCorrect" || currentState === "postIncorrect") && (lastCorrect !== undefined) && (
                    <div>
                        <h3>{currentState === "postCorrect" ? "Correct!" : "Better luck next time! The correct answer was " + (gameContext.difficulty === "hard" ? lastCorrect.taxon.name : lastCorrect.taxon.common_name.name)}</h3>
                        <h5><a href={lastCorrect.uri} target="_blank" rel="noopener noreferrer"> Check out the original iNaturalist post! </a></h5>
                    </div>
                )
            }
            {
                // 'next' button
                (currentState === "postCorrect") && (gameContext.state === "gameStarted") && (
                    <NextQuestionButton buttonLabel="Next Species" onClick={getNextDataSet} disabled={currentState !== "initial"}/>
                )
            }
            {
                // loading text
                (currentState === "loading") && (gameContext.state === "gameStarted") && (
                    <h3>Loading next species...</h3>
                )
            }
            {
                // guess options panel
                (currentState === "asking") && 
                (
                    <div>
                        <p>
                            <SpeciesGuessButtonsPanel difficulty={gameContext.difficulty} guessOptions={speciesChoices} onGuessMade={(guessID) => onGuessMade(guessID)}/>
                        </p>
                    </div>
                )
                
            }
            <br/><br/> {/*lol*/}
        </div> 
    )
}