import './App.css';
import CoolPic from './Noahs_iNat_game_with_text.png';
import StreakCounterComponent from './StreakCounterComponent';
import SpeciesGuessInterfaceComponent from './SpeciesGuessInterfaceComponent';
import GameStartOptionsPanel from './GameStartOptionsPanel';
import { createContext, useState } from 'react';

const GameContext = createContext();
let GeoLocation = {};

export default function App() {
  const [gameState, setGameState] = useState({
    score : 0,
    state : "initial",
    difficulty : "",
    taxon: ""
  });

  function onGuessMade(bCorrect){
    let newScore = bCorrect ? gameState.score + 1 : 0;

    let highScore = localStorage.getItem("highscore_" + gameState.taxon + gameState.difficulty);
    if (newScore !== 0 && (highScore === null || highScore < newScore) ){
      localStorage.setItem("highscore_" + gameState.taxon + gameState.difficulty, newScore);
    }

    setGameState({score: newScore, state: bCorrect ? gameState.state : "postGame", difficulty : gameState.difficulty, taxon: gameState.taxon});
  }

  function onGameStarted(taxon, difficulty){
    setGameState({score: gameState.score, state: "gameStarted", difficulty: difficulty, taxon: taxon});
  }

  if (gameState.state === "initial"){
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        GeoLocation = position;
        setGameState({score: 0, state: "preGame", difficulty : gameState.difficulty, taxon: gameState.taxon});
      });
    } else {
      setGameState({score: 0, state: "geoFailed", difficulty : gameState.difficulty, taxon: gameState.taxon});
    }  
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>
            EcoGuesser
        </h1>
        <p>
          Learn about your local ecology by guessing the species in these photographs taken by iNat users near you!
        </p>
      </header>
      <div className="AppBody">
        { // Pre game image stuff
          (gameState.state === "initial" || gameState.state === "preGame") &&
          <img src={CoolPic} width="256" height="256" alt='Thanks Lauren!!'/>
        }
        { // Loading info
          gameState.state === "initial" && (
          <div>
            <h2>Trying to get your location...</h2>
          </div>
        )}
        { // Game image/choices/scoreboard
          (gameState.state !== "initial"  && gameState.state !== "geoFailed" && gameState.state !== "preGame") && (
          <div>
            <GameContext.Provider value={gameState}>
              <br/><br/>
              <SpeciesGuessInterfaceComponent context={GameContext} geoLocation={GeoLocation} onGuessMade={(bCorrect) => onGuessMade(bCorrect)}/>
              <StreakCounterComponent context={GameContext}/>
            </GameContext.Provider>
          </div>
        )}
        { // Difficulty select/game start
          (gameState.state === "preGame" || gameState.state === "postGame") &&
          (
            <div>
              <h4>{gameState.state === "preGame" ? "Select a taxon and difficulty" : "Play again?"}</h4>
              <GameContext.Provider value={gameState}>
                <GameStartOptionsPanel context={GameContext} onButtonPressed={(taxon, difficulty) => onGameStarted(taxon, difficulty)}/>
              </GameContext.Provider>
            </div>
          )
        }
        {gameState.state === "geoFailed" && (
          <div>
            <h2>Couldn't find your geolocation! Please make sure to 'allow' location access in your browser. You can't enter it manually yet bc I'm lazy</h2>
          </div>
        )}
      </div>
      <footer className="AppFooter">
        <h4>
          <a
            className="App-link"
            href="https://www.youtube.com/watch?v=2yJgwwDcgV8&autoplay=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to Play                                         
          </a>
        </h4>
        <h6>
        <a href="http://www.freepik.com">Background designed by macrovector / Freepik</a>
        </h6>
      </footer>
    </div>
  );
}